import React from "react";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { WbdItemProps, DataProps } from "../../../../interfaces/interfaces";
import wbdImage from "../../../../utils/images/page3_vert.png";

const styles = StyleSheet.create({
  body: {
    margin: 0,
  },
  image: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
  },
  tool: {
    position: "relative",
    top: 60,
    left: 138,
    width: "50px",
  },
  toolTol: {
    position: "relative",
    top: 60,
    left: 79.5,
    width: "50px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  line: {
    position: "relative",
    top: 60,
    left: 140,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  lineTol: {
    position: "relative",
    top: 60,
    left: 85,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    position: "relative",
    top: 60,
    left: 150,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  innerTool: {
    position: "relative",
    top: 60,
    left: 87,
    width: "50px",
    alignItems: "center",
    justifyContent: "center",
  },
  lineInner: {
    position: "relative",
    top: 60,
    left: 90,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionInner: {
    position: "relative",
    top: 60,
    left: 100,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerToolTol: {
    position: "relative",
    top: 60,
    left: 29,
    width: "50px",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLeftTOL: {
    position: "absolute",
    top: 0,
    left: "31.9%", // Centra la línea horizontalmente
    width: "4px", // Ajusta el grosor de la línea
    height: "85px",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageLeftTOL: {
    position: "absolute",
    top: 0,
    left: "33%", // Centra la línea horizontalmente
    width: "2px", // Ajusta el grosor de la línea
    height: "85px",
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageTOLFirst: {
    height: "120px",
    transform: "translate(-50%, 60%)",
  },
  imageRightTOL: {
    position: "absolute",
    top: 0,
    left: "38.6%", // Centra la línea horizontalmente
    width: "4px", // Ajusta el grosor de la línea
    height: "85px",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageRightTOL: {
    position: "absolute",
    top: 0,
    left: "38%", // Centra la línea horizontalmente
    width: "2px", // Ajusta el grosor de la línea
    height: "85px", // Asegura que la línea cubra toda la altura de la pantalla
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineInnerTol: {
    position: "relative",
    top: 60,
    left: 30,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionInnerTol: {
    position: "relative",
    top: 60,
    left: 40,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTol: {
    position: "relative",
    top: 60,
    left: 100,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  tolDepth: {
    position: "relative",
    top: 60,
    left: 65,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  tolDepthAfterBottom: {
    position: "relative",
    top: 100,
    left: 70,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
});
export const WbdLastPageVert = ({
  tools,
  data: {
    lastBottom,
    basicInfo: { bhaInfo },
  },
}: {
  tools: WbdItemProps[];
  data: DataProps;
}) => {
  const startIndex = tools.findIndex((item) => item.tol === true);
  return (
    <Page style={styles.body}>
      <Image cache style={styles.image} src={wbdImage} fixed />
      <View style={styles.column}>
        {tools.map((item, index) =>
          item && item.tool.imagePath ? (
            <View style={styles.row} key={index}>
              {item.tol && (
                <Text style={styles.tolDepth}>TOL @ {bhaInfo?.tol} MD ft</Text>
              )}
              <>
                {/* {item.tol ? (<>
                  <View style={{...styles.imageLeftTOL, borderTop: "2px solid black"}} />
                  <View style={styles.lineImageLeftTOL} />
                </>
                ) : index > startIndex ? (<>
                  <View style={index === 0 && startIndex === -1 ? { ...styles.imageLeftTOL, ...styles.lineImageTOLFirst } : styles.imageLeftTOL} />
                  <View style={index === 0 && startIndex === -1 ? { ...styles.lineImageLeftTOL, ...styles.lineImageTOLFirst } : styles.lineImageLeftTOL} />
                </>) : null} */}
                <Image
                  key={index}
                  cache
                  style={item.tol ? styles.toolTol : styles.tool}
                  src={`${process.env.REACT_APP_SERVER}${item.tool.imagePath}`}
                  fixed
                />
              </>
              {/* {item.tol ? (
                <>
                  <View
                    style={{
                      ...styles.imageRightTOL,
                      borderTop: "2px solid black",
                    }}
                  />
                  <View style={styles.lineImageRightTOL} />
                </>
              ) : index > startIndex ? (
                <>
                  <View
                    style={
                      index === 0 && startIndex === -1
                        ? {
                            ...styles.imageRightTOL,
                            ...styles.lineImageTOLFirst,
                          }
                        : styles.imageRightTOL
                    }
                  />
                  <View
                    style={
                      index === 0 && startIndex === -1
                        ? {
                            ...styles.lineImageRightTOL,
                            ...styles.lineImageTOLFirst,
                          }
                        : styles.lineImageRightTOL
                    }
                  />
                </>
              ) : null} */}
              {item.tool.innerTools ? (
                <>
                  <Image
                    cache
                    style={item.tol ? styles.innerToolTol : styles.innerTool}
                    src={`${process.env.REACT_APP_SERVER}${item.tool.innerTools.imagePath}`}
                    fixed
                  />
                  <Text
                    key={index}
                    style={item.tol ? styles.lineInnerTol : styles.lineInner}
                    fixed
                  >
                    _________
                  </Text>
                  <Text
                    key={index}
                    style={
                      item.tol
                        ? styles.descriptionInnerTol
                        : styles.descriptionInner
                    }
                    fixed
                  >
                    {item.tool.osi
                      ? item.tool.description || item.tool.name
                      : item.tool.name}{" "}
                    {lastBottom &&
                      index === tools.length - 1 &&
                      `@ ${lastBottom.toFixed(2)} MD ft`}
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    key={index}
                    style={item.tol ? styles.lineTol : styles.line}
                    fixed
                  >
                    _________
                  </Text>
                  <Text
                    key={index}
                    style={
                      item.tol ? styles.descriptionTol : styles.description
                    }
                    fixed
                  >
                    {item.tool.osi
                      ? item.tool.description || item.tool.name
                      : item.tool.name}{" "}
                    {lastBottom &&
                      index === tools.length - 1 &&
                      `@ ${lastBottom.toFixed(2)} MD ft`}
                  </Text>
                </>
              )}
            </View>
          ) : (
            ""
          )
        )}
        {bhaInfo?.tol && lastBottom && bhaInfo.tol > lastBottom && (
          <Text style={styles.tolDepthAfterBottom}>
            TOL @ {bhaInfo?.tol} MD ft
          </Text>
        )}
      </View>
    </Page>
  );
};
